<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="container--fluid">
      <v-toolbar tile flat style="max-height: 38px">
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-on:click="goBack" style="cursor: pointer">Go back</v-toolbar-title>
        <v-spacer/>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="openDialog">
              <v-icon color="primary" size="36px">mdi-bookmark-check</v-icon>
            </v-btn>
          </template>
          <span>I'm interested</span>
        </v-tooltip>
        <v-tooltip top v-if="isFavorite">
          <template v-slot:activator="{ on, plain, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="toggleFavorite">
              <v-icon style="color: #f80359" size="36px">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, plain, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon plain large @click="toggleFavorite">
              <v-icon style="color: #c95e85" size="36px">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <v-container class="fill-height" fluid>
      <!--<v-container fill-height fluid text-xs-center class="mx-3">-->
      <v-row align="start" justify="start">
        <v-col cols="12" md="5">
          <v-container class="mx-0" fluid>
            <v-row no-gutters>
              <v-col cols="12" class="mb-1">
                <span class="text-h5 font-weight-ligh headline text-uppercase">
                  {{ profile.name }}
                </span>
              </v-col>
              <v-row v-if="profile.id" class="mb-1">
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="profile.basic" disabled v-bind="attrs" v-on="on" large @click="openReservationForm()"
                             style="width: 100%; background-color: #691e32; color: white"
                      >
                        <!-- <v-icon color="green" size="44px">mdi-plus</v-icon> -->

                        <img src="@/assets/images/cart.svg" alt="cart" style="
                        object-fit: contain;
                        width: 24px;
                        height: 24px;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      "/>

                        <h3 class="text-uppercase" style="color: white">
                          Add to itinerary
                        </h3>
                      </v-btn>
                      <v-btn v-if="!profile.basic" v-bind="attrs" v-on="on" large @click="openReservationForm()"
                             style="width: 100%; background-color: #691e32; color: white">
                        <img src="@/assets/images/cart.svg" alt="cart" style="
                        object-fit: contain;
                        width: 24px;
                        height: 24px;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      "/>

                        <h3 class="text-uppercase" style="color: white">
                          Add to itinerary
                        </h3>
                      </v-btn>
                    </template>
                    <span>Add to itinerary</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="profile.basic" disabled :href="`tel:${profile.phone1}`" v-bind="attrs" v-on="on"
                             large style="width: 100%;"
                             color="success" @click="registerCallUSVisit">
                        <v-icon color="white" style="margin-right: 5%;">mdi-phone</v-icon>

                        <h3 class="text-uppercase" style="color: white">
                          Call us
                        </h3>
                      </v-btn>
                      <v-btn v-if="!profile.basic" :href="`tel:${profile.phone1}`" v-bind="attrs" v-on="on" large
                             style="width: 100%;" @click="registerCallUSVisit" color="success">
                        <v-icon color="white" style="margin-right: 5%;">mdi-phone</v-icon>

                        <h3 class="text-uppercase" style="color: white">
                          Call us
                        </h3>
                      </v-btn>
                    </template>
                    <span>Call us</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-col cols="12" class="mb-1 img-container">
                <v-carousel cycle :show-arrows="false">
                  <v-carousel-item v-for="item in profile.pictures" :key="item.id">
                    <v-img :lazy-src="require('@/assets/images/no-image.jpg')" :src="item.url"
                           class="img-doctor"></v-img>
                    <img v-if="profile.premium" src="@/assets/images/icons/premium.png"
                         alt="sello"
                         class="superior-derecha"/>
                    <img v-else-if="profile.basic" src="@/assets/images/icons/basic.png"
                         alt="sello"
                         class="superior-derecha"/>
                    <img v-else src="@/assets/images/icons/member.png"
                         alt="sello"
                         class="superior-derecha"/>
                    <img v-show="profile.excellence" src="@/assets/images/icons/escudo_coepris.f8331f99.png"
                         alt="sello1"
                         class="superior-derecha-segunda"/>
                    <img v-show="profile.repsabi" src="@/assets/images/icons/repssabi.png" alt="sello1"
                         class="superior-derecha-tercera"/>
                  </v-carousel-item>
                </v-carousel>
              </v-col>

              <!-- Excelencia -->
              <v-col v-if="profile.excellence && profile.repsabi">
                <div style="display: flex; align-items: center;justify-content: center; height: 110px; width: 100%"
                     class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#bcycoepris" src="@/assets/images/bcycoepris.png" alt="" class="img-qr-rigth"/>
                  <map name="bcycoepris">
                    <area
                        shape="rect"
                        coords="0,0,300,110"
                        :href="profile.coepris_qr_link"
                        :target="profile.target2"
                        alt="HTTP"/>
                    <area
                        shape="rect"
                        coords="302,0,634,110"
                        :href="'#'"
                        alt="HTTP"/>
                  </map>
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr"/>
                  </a>
                </div>
              </v-col>

              <!-- Turismo -->
              <v-col v-else-if="profile.excellence &&  profile.repsabi === null">
                <div style="display: flex; align-items: center;justify-content: center; height: 110px; width: 100%"
                     class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#only-catalogo" src="@/assets/images/only-catalogo.png" alt="" class="img-qr-rigth"/>
                  <map name="only-catalogo">
                    <area
                        shape="rect"
                        coords="0,0,634,110"
                        :href="profile.coepris_qr_link"
                        :target="profile.target2"
                        alt="HTTP"/>
                  </map>
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr"/>
                  </a>
                </div>
              </v-col>

              <!-- Coepris -->
              <v-col v-else-if="profile.repsabi && profile.excellence === null">
                <div style="display: flex; align-items: center;justify-content: center; height: 110px; width: 100%"
                     class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#only-coepris" src="@/assets/images/only-coepris.png" alt="" class="img-qr-rigth"/>
                  <map name="only-coepris">
                    <area
                        shape="rect"
                        coords="0,0,634,110"
                        :href="'#'"
                        alt="HTTP"/>
                  </map>
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr"/>
                  </a>
                </div>
              </v-col>
              <v-col cols="12">
                <h3 class="ms-2">FAQ's</h3>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is COEPRIS?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The COEPRIS (State Commission for Protection against Health Risks) in Mexico is a state-level
                      health authority responsible for regulating and ensuring the compliance of sanitary standards in
                      various sectors. Its main focus is on preventing and managing health risks related to food safety,
                      water quality, medical services, drugs, and general public health. COEPRIS works to protect the
                      population from health hazards by enforcing laws, conducting inspections, and promoting sanitary
                      practices.

                      <a target="_blank" href="https://www.gob.mx/cofepris">Go to COEPRIS</a>.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is the Secretary of Tourism?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The Secretaría de Turismo de Baja California is responsible for promoting the development of the
                      state’s tourism industry. Its mission is to formulate, implement, and evaluate plans and programs
                      that foster sustainable tourism, attract investment, generate employment, and improve the quality
                      of life for residents. The agency works to position Baja California as a competitive destination
                      both nationally and internationally, focusing on key areas such as eco-tourism, cultural tourism,
                      and gastronomic tourism ￼ ￼.
                      For more information, you can visit their official site <a target="_blank"
                                                                                 href="https://www.bajacalifornia.gob.mx/secture/Conocenos/Mision">here</a>
                      ￼.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is State Catalog of Excellence in Health and Wellness Tourism (CEETSB):?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      CEETSB is a pioneering project developed by the Baja California Secretariat of Tourism (SECTURE).
                      Its purpose is to identify and promote globally the highest quality health and wellness service
                      providers in the region, complying with strict national and international standards of medical
                      care
                      and patient safety.

                      This initiative plays a crucial role in Baja California's strategy to promote the state as a
                      leader
                      in health and wellness tourism, allowing visitors to easily identify health establishments and
                      professionals
                      certified and accredited under current national and/or international health regulatory standards.

                      CEETSB also supports Baja California's status as the "Health Tourism Capital of Mexico", ensuring
                      that
                      both local residents and international visitors have access to safe and excellent medical and
                      wellness
                      services.

                      By being included in this catalog, health and wellness establishments and professionals
                      demonstrate
                      that they meet the highest standards of quality, care and service to the specialized market in
                      health
                      and wellness tourism with current international accreditations and certifications.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is The State Registry of Health and Wellness Service Providers (REPSSABI)?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      REPSSABI seeks to promote a culture of self-regulation in health care establishments through
                      the accompaniment and health promotion by COEPRIS, helping to strengthen the best health
                      practices,
                      the quality and safety of the medical services provided in our region. Its purpose is to ensure
                      that all health and wellness service providers in the state comply with strict safety, quality and
                      regulatory standards.

                      By being included in this registry, establishments such as hospitals, medical clinics, dental
                      offices,
                      recovery centers and medical spas demonstrate that they comply with their legal and technical
                      documentation
                      that supports their legality and good practices in the state.

                      For more details, you can visit the official site: <a target="_blank"
                                                                            href="https://tramites.bajacalifornia.gob.mx/tu_belleza_con_certeza_4abf9f93-214d-4f25-a107-860c644576df">REPSSABI</a>
                      official site.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col v-if="!profile.basic" cols="12">
                <v-container class="pa-0 mt-4" fluid>
                  <div :id="containerID" :style="containerCSS"></div>
                  <v-btn depressed class="mt-1" style="background-color: #691e32; color: white"
                         block @click="goAddress">
                    <v-icon class="me-2">mdi-map-marker</v-icon>
                    How to get there
                  </v-btn>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="7">
          <v-card class="ml-1" elevation="0" ou>
            <v-container class="mx-0" fluid>
              <v-row v-if="profile.excellence && profile.id!==293 && profile.id!==292" no-gutters>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Certifications: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <!-- DR. JEFFRY ROMERO MANZANO -->
                <v-col v-if="profile.id===308" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/jeffry_romero_manzano/certs/jeffry_romero_manzano_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/jeffry_romero_manzano/certs/jeffry_romero_manzano_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!--Dr. Jorge Andrés Felix Bulman -->
                <v-col v-if="profile.id===261" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/jorge_andres_felix/certs/andres_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cardiologia.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/jorge_andres_felix/certs/andres_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!--Ricardo Ulises Pineda-->
                <v-col v-if="profile.id===268" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_ulises_pineda/certs/ricardo_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_nacional_mexicano_de_urologia.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_ulises_pineda/certs/ricardo_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!--Dr. Aureliano Moreno Andrade-->
                <v-col v-if="profile.id===291" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificadoconsejo.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/oftalmolog.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/aureliano_morelos/certs/aureliano_cert_consejo.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="650">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/inst_oftalmologia.jpg"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/aureliano_morelos/certs/aureliano-examen-instituto-de-oftalmologia.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/international_council_ophthalmology.jpg"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificado-instituto-oftalmologia.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/unam.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/aureliano_morelos/certs/unam-aureliano.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!--Carlos Marcel Chavarría Duarte -->
                <v-col v-if="profile.id===300" cols="12" sm="12">


                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/carlos_marcell/certs/carlos_marcell_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/logo-consejo-nacional-noumologia.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/carlos_marcell/certs/carlos_marcell_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!-- Doctora Liza Maria Pompa-->
                <v-col v-if="profile.id===304" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_master.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/liza_maria_pompa/certs/mastersurgeon_metabolic.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_excellence.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/liza_maria_pompa/certs/surgeonofexcellence.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/liza_maria_pompa/certs/liza_cmcga.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <img src="@/assets/images/certifications/bariatrico.jpg"
                       alt="sello"
                       class="certification-icon-card"
                  />

                  <img src="@/assets/images/certifications/consejo_salubridad_general.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                  <img src="@/assets/images/certifications/bariatric_surgeon_excellence.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                  <img src="@/assets/images/certifications/center_of_exellence.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                  <img src="@/assets/images/certifications/international_center_of_exellence.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                  <img src="@/assets/images/certifications/global_health_accreditation.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                </v-col>

                <!-- Dr. Arturo Martínez Gamboa -->
                <v-col v-if="profile.id===305" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_master.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/arturo_martinez/certs/arturo_martinez_2.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_excellence.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/arturo_martinez/certs/arturo_martinez_1.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/arturo_martinez/certs/arturo_martinez_3.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/ASMBS.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/arturo_martinez/certs/arturo_martinez_3.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/arturo_martinez/certs/arturo_martinez_3.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>

                <!-- Dr. Nicolás Zarur Mina -->
                <v-col v-if="profile.id===237" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/nicolas_zarur_mina/certs/zarur_cert.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_ortopedia.jpg"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/nicolas_zarur_mina/certs/zarur_cert.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!-- Dr. Ariel Alfonso Alfredo Ortiz Lagardere  -->
                <v-col v-if="profile.id===12" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_excellence.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_surgeon_excellence.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_master.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_master_surgeon_metabolic.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ortiz_conacem.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/ASMBS.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_asmbs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/ACS.svg"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_acs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ortiz_cmcp.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>

                <!-- Dr. José Sergio Verboonen Sotelo  -->
                <v-col v-if="profile.id===283" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/jose_sergio_berboonen/certs/jose_sergio_berboonen_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>


                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/jose_sergio_berboonen/certs/jose_sergio_berboonen_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Javier Eugenio Venegas Rodríguez  -->
                <v-col v-if="profile.id===290" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/amo.jpeg"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/javier_eugenio_venegas/certs/javier_eugenio_venegas_6.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/WFO.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/javier_eugenio_venegas/certs/javier_eugenio_venegas_3.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/Asociacion_Dental_Mexicana.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/javier_eugenio_venegas/certs/javier_eugenio_venegas_1.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/aao.jpg"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/javier_eugenio_venegas/certs/javier_eugenio_venegas_4.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/alo.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/javier_eugenio_venegas/certs/javier_eugenio_venegas_5.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Ricardo Vega Montiel  -->
                <v-col v-if="profile.id===303" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/conacem_vega.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmcpr.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA-TIJUANA-1.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmcper.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/veta-cert.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="650">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/isaps.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/MemberCertificate10374-2.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="650">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/unam.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-RICARDO-3.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/filacp.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados11.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/ccper.jpg"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados27.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/safe-plastic-surgery.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados14.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/isaps-leap-no-bg.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ricardo_vega_montiel/certs/ISAPS-2022_Certificate-of-Presentation_Vega-Ricardo-1.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/american-society-of-plastic-surgeon.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA.jpg"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/AMCPER.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_11727.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="650">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/safety-no-bg.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/certs/MemberCertificate10374-2.jpg"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Ruben Aldrete Vazquez  -->
                <v-col v-if="profile.id===288" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ruben_aldrete_vazquez/certs/ruben_aldrete_vazquez_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmmi.jpeg"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/ruben_aldrete_vazquez/certs/ruben_aldrete_vazquez_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Alejandro López Gaxiola  -->
                <v-col v-if="profile.id===302" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/alejandro_lopez_gaxiola/certs/alejandro_lopez_gaxiola_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmcpr.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/alejandro_lopez_gaxiola/certs/alejandro_lopez_gaxiola_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!-- Dr. Carlos Vicente Bazán Pérez  -->
                <v-col v-if="profile.id===289" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/carlos_vicente_bazan_perez/certs/carlos_vicente_bazan_perez_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmmi.jpeg"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/carlos_vicente_bazan_perez/certs/carlos_vicente_bazan_perez_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                </v-col>
                <!-- Dr. Selim Jalil Illan Fraijo  -->
                <v-col v-if="profile.id===269" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_excellence.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/selim_jalil/certs/selim_surg_excellence.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/surgion_master.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/selim_jalil/certs/selim_master_surg.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/selim_jalil/certs/selim_conacem.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/selim_jalil/certs/selim_conacem.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Juan Valente Mérida Palacio  -->
                <v-col v-if="profile.id===238" cols="12" sm="12">
                  <img src="@/assets/images/certifications/conacem.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                  <img src="@/assets/images/certifications/consejo_mexicano_de_cirugia_general.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                </v-col>
                <!-- Dra. Jennifer Vanessa Ramirez Puente  -->
                <v-col v-if="profile.id===69" cols="12" sm="12">
                  <img src="@/assets/images/certifications/conacem.png"
                       alt="sello"
                       class="certification-icon-card"
                  />
                </v-col>
                <!-- Dr. Alejandro Lira Dale -->
                <v-col v-if="profile.id===272" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/alejandro_lira_dale/certs/lira_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>


                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/consejo_nacional_mexicano_de_urologia.png"
                           alt="sello"
                           class="documents-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/alejandro_lira_dale/certs/lira_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Julio César Zepeda Najar -->
                <v-col v-if="profile.id===287" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/julio_cesa_zepeda_najar/certs/julio_cesa_zepeda_najar_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>


                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/Consejo-Mexicano-de-Oncologia.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img
                                src="@/assets/images/documents/julio_cesa_zepeda_najar/certs/julio_cesa_zepeda_najar_certs.png"
                                alt="sello"
                                class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Josué Delgado Gutiérrez -->
                <v-col v-if="profile.id===267" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/josue_delgado_gutierrez/certs/josue_delgado_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/oftalmolog.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/josue_delgado_gutierrez/certs/josue_delgado_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Adelita Díaz Ramírez -->
                <v-col v-if="profile.id===298" cols="12" sm="12">
                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/adelita_diaz/certs/adelita_cert.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/logo-consejo-nacional-noumologia.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/adelita_diaz/certs/adelita_cert.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <!-- Dr. Luis Victoria Laborin -->
                <v-col v-if="profile.id===276" cols="12" sm="12">

                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/conacem.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/luis_victoria_laborin/certs/victoria_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>


                  <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <img src="@/assets/images/certifications/cmgo.png"
                           alt="sello"
                           class="certification-icon-card"
                           v-bind="attrs"
                           v-on="on"
                      />
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-text>
                          <div class="zoom-container">
                            <img src="@/assets/images/documents/luis_victoria_laborin/certs/victoria_certs.png"
                                 alt="sello"
                                 class="certification-icon-card-zoom"
                            />
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                              text
                              @click="dialog.value = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <span class="text-button font-weight-medium">Description:
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col>
                      <div class="d-flex align-start">
                        <v-icon style="color:  #691e32" class="me-2">mdi-format-quote-close</v-icon>
                        <div class="text-container" :class="{ 'expanded': descriptionExpand }" ref="descriptionSpan">
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{
                          profile.description_english
                              ? profile.description_english
                              : profile.description
                        }}
                      </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Address: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-map-marker</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.address }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">E-mail: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-email</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.email }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Phones: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-phone</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <a v-show="profile.phone1!==null && profile.phone1!=='null'"
                           :href="'tel:' + profile.phone1">{{ profile.phone1 | formatPhone("MX") }} </a>
                        <span v-show="profile.phone2!==null && profile.phone2!=='null'">{{
                            profile.phone2 | formatPhone(" , ")
                          }}</span>
                        <a v-show="profile.phone2!==null && profile.phone2!=='null'"
                           :href="'tel:' + profile.phone2">{{ profile.phone2 | formatPhone("US") }}</a>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">Professional License Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.profesional_license_number }}
                      </span>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Specialty License Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.specialty_license_number }}
                      </span>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Valid Certification:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.certification_validity }}
                      </span>
                    </v-col>
                  </v-row>
                  <span v-if="profile.website!==null" class="text-button font-weight-medium">Website:
                  </span>
                  <v-divider v-if="profile.website!==null"></v-divider>
                  <v-row v-if="profile.website!==null" align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-web</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <a :href="profile.website" target="_blank">{{ profile.website }}</a>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">Certificate Number:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.certificate_number }}
                      </span>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Professional License Verification:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-certificate</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.professional_license_verification }}
                      </span>
                    </v-col>
                  </v-row>
                  <span class="text-button font-weight-medium">Attention schedule:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-clock</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.servicehours }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="!profile.basic">
                  <span class="text-button font-weight-medium">Social media:</span>
                </v-col>
                <v-col v-if="!profile.basic" cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col v-if="!profile.basic" cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col v-show="profile.facebookprofile!==null" cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :href="profile.facebookprofile" color="blue" icon
                                 target="_blank">
                            <v-icon color="primary" size="24px">mdi-facebook</v-icon>
                          </v-btn>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-show="profile.instagramprofile!==null" cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :href="profile.instagramprofile" color="#D93F8FFF" icon
                                 target="_blank">
                            <v-icon size="24px">mdi-instagram</v-icon>
                          </v-btn>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-show="profile.tweeterprofile!==null" cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :href="profile.tweeterprofile" icon target="_blank">
                            <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                          </v-btn>
                        </template>
                        <span>X</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-show="profile.youtubeprofile!==null" cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :href="profile.youtubeprofile" color="red" icon
                                 target="_blank">
                            <v-icon size="24px">mdi-youtube</v-icon>
                          </v-btn>
                        </template>
                        <span>Youtube</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-show="profile.tiktok!==null" cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" :href="profile.tiktok" color="red" icon
                                 target="_blank">
                            <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
                          </v-btn>
                        </template>
                        <span>Tick Tock</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="start" justify="start">
                    <v-col cols="12">
                      <div v-if="profile.specialties">
                        <span class="text-button font-weight-medium">Specialties:</span><br/>
                        <v-chip v-for="(s, i) in profile.specialties" :key="i" class="mr-1">
                          {{ s.name_english ? s.name_english : s.name }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="start" justify="start">
                    <v-col cols="12">
                      <v-row v-if="profile.excellence && profile.id!==293 && profile.id!==292" no-gutters>
                        <v-col cols="12">
                          <span class="text-button font-weight-medium">Documents and awards: </span>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <!--Dr. Jorge Andrés Felix Bulman -->
                        <v-col v-if="profile.id===261" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/jorge_andres_felix/1712257905_9-_Consejo_cardiologia_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/jorge_andres_felix/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!--Ricardo Ulises Pineda-->
                        <v-col v-if="profile.id===268" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/ricardo_ulises_pineda/1712337345_8._Consejo_de_Especialidad_Urologia_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/ricardo_ulises_pineda/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!--Dr. Aureliano Moreno Andrade-->
                        <v-col v-if="profile.id===291" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/aureliano_morelos/1716398571_DR._MORENO_ANDRADE_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/aureliano_morelos/oftamology.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificado-instituto-oftalmologia.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificado-instituto-oftalmologia.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificadoconsejo.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/aureliano_morelos/certs/aureliano-certificadoconsejo.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/aureliano_morelos/certs/unam-aureliano.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/aureliano_morelos/certs/unam-aureliano.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/aureliano_morelos/certs/aureliano-examen-instituto-de-oftalmologia.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/aureliano_morelos/certs/aureliano-examen-instituto-de-oftalmologia.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-col>
                        <!--Carlos Marcel Chavarría Duarte -->
                        <v-col v-if="profile.id===300" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/carlos_marcell/1726719373_CONACEM_CARLOS_CHAVARRIA__watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/carlos_marcell/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!-- Doctora Liza Maria Pompa-->
                        <v-col v-if="profile.id===304" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/liza_maria_pompa/1716679193_certificaciones_nacionales_e_internacionales_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/liza_maria_pompa/caratula_liza.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/liza_maria_pompa/certs/liza_cmcga.png"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/liza_maria_pompa/certs/liza_cmcga.png"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="700">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/liza_maria_pompa/certs/mastersurgeon_metabolic.png"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/liza_maria_pompa/certs/mastersurgeon_metabolic.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="700">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/liza_maria_pompa/certs/surgeonofexcellence.png"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/liza_maria_pompa/certs/surgeonofexcellence.png"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                        </v-col>
                        <!-- Dr. Arturo Martínez Gamboa -->
                        <v-col v-if="profile.id===305" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/arturo_martinez/1726888904_Certificado_CONACEM_Arturo_Martinez_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/carlos_marcell/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!--Dr. Alejandro Nava Rivas -->
                        <!--Vacio-->
                        <!--Dr. Nicolás Zarur Mina-->
                        <v-col v-if="profile.id===237" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/nicolas_zarur_mina/1711395655_CERTIFICADO_CONSEJO_MEXICANO__2__watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/nicolas_zarur_mina/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- DR. JEFFRY ROMERO MANZANO -->
                        <v-col v-if="profile.id===308" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="http://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/jeffry_romero_manzano/1727472338_conacemjeffry_watermark.pdf"
                             target="_blank">
                            <img
                                src="@/assets/images/documents/jeffry_romero_manzano/certs/jeffry_romero_manzano_certs.png"
                                alt="sello"
                                class="documents-icon-card"
                            />
                          </a>
                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/jeffry_romero_manzano/conacem.png"
                                   alt="sello"
                                   class="certification-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/jeffry_romero_manzano/certs/jeffry_romero_manzano_certs.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                        </v-col>
                        <!--Ariel Alfonso Alfredo Ortiz Lagardere -->
                        <v-col v-if="profile.id===12" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/ariel_alfonso_alfredo_ortiz_lagardere/1726713158_Constancias_Ariel_Ortiz_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/Conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_surgeon_excellence.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_surgeon_excellence.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_master_surgeon.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_master_surgeon.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="750">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_master_surgeon_metabolic.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_master_surgeon_metabolic.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="750">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_asmbs.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_asmbs.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="750">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_acs.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ariel_acs.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ortiz_cmcp.png"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ariel_alfonso_alfredo_ortiz_lagardere/certs/ortiz_cmcp.png"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-col>
                        <!-- Dr.Adelita Diaz -->
                        <v-col v-if="profile.id===298" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/adelita_díaz/1726893671_2021-2026_CNN_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/adelita_diaz/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>

                        </v-col>
                        <!-- Dr.Alejandro Lira -->
                        <v-col v-if="profile.id===272" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/alejandro_lira_dale/1721232920_CONACEM__-_Alejandro_Lira_Dale_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/alejandro_lira_dale/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!-- Dr.Alejandro Lopez -->
                        <v-col v-if="profile.id===302" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/alejandro_lopez_gaxiola/1726892360_CONACEM-1_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/alejandro_lopez_gaxiola/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!-- Dr. Carlos vicente -->
                        <v-col v-if="profile.id===289" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/carlos_vicente_bazan_perez/1721233330_1720132892_CERTIFICACION__VENCE_2026__watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/carlos_vicente_bazan_perez/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!-- Dr. Javier Eugenio Venegas -->
                        <v-col v-if="profile.id===290" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/javier_eugenio_venegas/1717613096_CONSTANCIAS_Y_CERTIFICACIONES_ORTODONCIA_2024_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/javier_eugenio_venegas/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                        <!-- Dr. Jorge Sergio Berboonen -->
                        <v-col v-if="profile.id===283" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/jose_sergio_berboonen/1708642106_CONSTANCIA_DE_LA_CERTIFICACION_DE_SU_ESPECIALIDADCONACEM_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/jose_sergio_berboonen/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- Dr. Josue Delgado Gutierrez -->
                        <v-col v-if="profile.id===267" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/josue_delgado_gutierrez/1721235227_CONACEM_CERTIFICACION_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/josue_delgado_gutierrez/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- Dr. Julio Cesar Cepeda Najar -->
                        <v-col v-if="profile.id===287" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/julio_cesa_zepeda_najar/1721235605_CARTA_DE_CERTIFICACION_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/julio_cesa_zepeda_najar/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- Dr. Luis Victoria Laborin -->
                        <v-col v-if="profile.id===276" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/luis_victoria_laborin/1721236202_CONACEM_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/luis_victoria_laborin/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- Dr. Ricardo Vega Montiel -->
                        <v-col v-if="profile.id===303" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/ricardo_vega_montiel/1709076665_CERTIFICADO_AMCPER_2022-2027_FRENTE_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/ricardo_vega_montiel/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/veta-cert.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/veta-cert.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados13.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados13.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados12.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados12.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados11.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados11.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados10.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados10.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados07.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados07.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados03.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados03.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA-TIJUANA-1.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-VEGA-TIJUANA-1.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-vega-2.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-vega-2.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-RICARDO-3.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/CERTIFICADOS-Y-TITULOS-DR-RICARDO-3.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados14.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados14.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados15.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados15.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados17.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados17.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados21.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados21.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados22.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados22.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados27.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/certificados27.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="750">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/ISAPS-2022_Certificate-of-Presentation_Vega-Ricardo-1.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/ISAPS-2022_Certificate-of-Presentation_Vega-Ricardo-1.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/GUERRERO-SANTOS-2023.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/GUERRERO-SANTOS-2023.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/Constancia-XXX-2023_927.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/Constancia-XXX-2023_927.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_14669.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_14669.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_14197-scaled.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_14197-scaled.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_11727.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_11727.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_10650.jpg"
                                   alt="sello"
                                   class="documents-icon-card"
                                   v-bind="attrs"
                                   v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img src="@/assets/images/documents/ricardo_vega_montiel/certs/constancia_10650.jpg"
                                         alt="sello"
                                         class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>


                          <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="650">
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                  src="@/assets/images/documents/ricardo_vega_montiel/certs/MemberCertificate10374-2.jpg"
                                  alt="sello"
                                  class="documents-icon-card"
                                  v-bind="attrs"
                                  v-on="on"
                              />
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-card-text>
                                  <div class="zoom-container">
                                    <img
                                        src="@/assets/images/documents/ricardo_vega_montiel/certs/MemberCertificate10374-2.jpg"
                                        alt="sello"
                                        class="certification-icon-card-zoom"
                                    />
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-col>

                        <!-- Dr. Ruben Aldrete Vazquez -->
                        <v-col v-if="profile.id===288" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/ruben_aldrete_vazquez/1721233870_1720327227_CERTIFICACION_VENCE_2024_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/ruben_aldrete_vazquez/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>

                        <!-- Dr. Selim Halil -->
                        <v-col v-if="profile.id===269" cols="12" sm="12">
                          <a title="Certificación nacional e internacional"
                             href="https://bht-store-qa.s3.us-east-1.amazonaws.com/Doctores/selim_jalil/1712778735_certificaciones.-Dr.JIF_watermark.pdf"
                             target="_blank">
                            <img src="@/assets/images/documents/selim_jalil/conacem.png"
                                 alt="sello"
                                 class="documents-icon-card"
                            />
                          </a>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="!profile.basic" cols="12">
                  <v-container v-if="profile.youtubeprofile != null" class="pa-0 mt-1" fluid @click="registerYoutubeVisit">
                    <span class="text-button font-weight-medium">Youtube video:</span><br/>
                    <iframe :src="youtubeVideo"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            frameborder="0" height="520" width="100%">
                    </iframe>
                    <!--</v-row>-->
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="background-color: #691e32" fluid>
      <v-row>
        <v-col cols="12">
          <v-container class="ma-0" fill-height fluid>
            <v-row align="center" class="mt-3 text-center" justify="center">
              <v-col>
                <span class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold">Our team of excellence specialist doctors</span>
              </v-col>
            </v-row>
            <v-row class="mt-5 pt-0 mb-5">
              <v-col cols="12">
                <div class="fill-height">
                  <v-slide-group v-model="model" class="pa-1"
                                 active-class="white"
                                 show-arrows>
                    <v-slide-item v-for="card in doctors" :key="card.id" v-slot="{ active, toggle }">
                      <v-card v-if="card.pictures[0] != null" :color="active ? undefined : 'white'"
                              class="ma-4 rounded-lg" elevation="10" height="400"
                              :width="$vuetify.breakpoint.mobile ? 300 : 310" @click="toggle">
                        <div v-on:click="getProfile(card.id)" style="overflow: hidden">
                          <img v-if="card.pictures[0] != null" :src="card.pictures[0].url"
                               style="height: 250px; width: 100%; object-fit: cover; object-position: top"/>
                          <img v-if="card.premium" src="@/assets/images/icons/premium.png"
                               alt="sello"
                               class="superior-derecha-card"/>
                          <img v-else src="@/assets/images/icons/member.png"
                               alt="sello"
                               class="superior-derecha-card"/>
                          <img v-show="card.excellence" src="@/assets/images/icons/escudo_coepris.f8331f99.png"
                               alt="sello1"
                               class="superior-derecha-segunda-card"/>
                          <img v-show="card.repsabi" src="@/assets/images/icons/repssabi.png" alt="sello1"
                               class="superior-derecha-tercera-card"/>

                          <div class="row">
                            <div class="col-12">
                              <v-card-subtitle
                                  class="pb-0 text-subtitle-1 indigo--text text-capitalize text-truncate">
                                {{ card.name }}
                              </v-card-subtitle>
                            </div>
                          </div>
                          <v-card-actions class="mt-1 justify-center">
                            <v-btn v-show="card.facebook_profile!==null" :href="card.facebook_profile" color="blue"
                                   icon target="_blank">
                              <v-icon color="primary" size="24px">mdi-facebook
                              </v-icon>
                            </v-btn>
                            <v-btn v-show="card.instagram_profile!==null" :href="card.instagram_profile"
                                   color="#D93F8FFF" icon target="_blank">
                              <v-icon size="24px">mdi-instagram
                              </v-icon>
                            </v-btn>
                            <v-btn v-show="card.tweeterprofile!==null" :href="card.tweeterprofile" icon
                                   target="_blank">
                              <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                            </v-btn>
                            <v-btn v-show="card.youtubeprofile!==null" :href="card.youtubeprofile" color="red" icon
                                   target="_blank">
                              <v-icon size="24px">mdi-youtube
                              </v-icon>
                            </v-btn>
                            <v-btn v-show="card.tiktok!==null" :href="card.tiktok" color="red" icon target="_blank">
                              <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
                            </v-btn>
                          </v-card-actions>
                        </div>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <footer-profile-component/>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row--dense mx-1 mt-2 modal-email">
            <h4 class="mb-3">Please share your email with us and we will contact you shortly</h4>
            <v-text-field v-model="email" :rules="emailRules" :error="email===''" label="Email"
                          error-messages="Please enter an email" required>
            </v-text-field>
          </div>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog"> Cancel</v-btn>
            <v-btn :disabled="!valid" color="primary" text @click="acceptDialog">
              Accept
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" v-model="reservationFormDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Reservation times</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-3">
            <v-col>
              <label>Reservation date</label>
              <v-dialog
                  ref="dialog1"
                  v-model="modal3"
                  :return-value.sync="reservation.date"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="reservation.date"
                      label="Select a date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="reservation.date"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal3 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog1.save(reservation.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Campo de hora -->
          <v-row>
            <v-col>
              <label>Reservation hour</label>
              <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="reservation.hour"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="reservation.hour"
                      label="Select a hour"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="modal2"
                    v-model="reservation.hour"
                    full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(reservation.hour)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeReservationForm">Cancel</v-btn>
          <v-btn color="success" @click="addToCart(profile)">Add appointment</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import doctorsService from "@/providers/DoctorsService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import preferencesService from "@/providers/PreferencesService";
import {changeInformationSEO} from "@/providers/KeywordsServices";
import Swal from 'sweetalert2'
import 'vue2-datepicker/index.css';
import {
  ADD_TO_ITINERARY_BUTTON, 
  ADD_TO_ITINERARY, 
  CALL_US, 
  IM_INTEREST, 
  LIKE, 
  HOW_TO_GET_THERE, 
  YOUTUBE_VIDEO, 
  VISIT_PROFILE, 
  PROFILE_NAME
} from "@/constant/doctor";
import { registerHistorial } from "@/providers/HistorialService";

//import Certification from '@/components/certifications/Certification.vue';

export default {
  name: "DoctorProfileComponent",
  components: {FooterProfileComponent},
  props: {
    doctor_id: Number,
    initialLocation: {
      type: Array,
      default: () => [-116.97480855890304, 32.547976944620714],
    },
    height: {
      type: String,
      default: "300px",
    },
    mapStyle: {
      type: String,
      default: "mapbox://styles/mapbox/streets-v11",
      // default: "mapbox://styles/mapbox/outdoors-v11",
    },
    color: {
      type: String,
      default: "orange",
    },
    containerID: {
      type: String,
      default: "map",
    },
  },
  data: () => ({
    modal2: false,
    modal3: false,
    showReadMore: false,
    establishmentName: '',
    reviews: [],
    loading: false,
    error: null,

    descriptionExpand: false,
    model: null,
    youtubeVideo: "",
    snackBar: false,
    snackText: "",
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    valid: true,
    dialog: false,
    email: null,
    location: [],
    doctors: [],
    profile: {
      id: 0,
      excellence: false,
      repsabi: false,
      premium: false,
      name: null,
      description: null,
      description_english: null,
      address: null,
      map_lng: null,
      map_lat: null,
      phone1: null,
      phone2: null,
      profesional_license_number: null,
      specialty_license_number: null,
      certification_validity: null,
      certificate_number: null,
      professional_license_verification: null,
      email: null,
      website: null,
      facebookprofile: null,
      instagramprofile: null,
      tweeterprofile: null,
      youtubeprofile: null,
      servicehours: null,
      pictures: [],
      specialties: [],
      certifications: [],
      coepris_qr: "",
      vencimiento_fecha: "",
      coepris_qr_link: "",
      coepris2_qr_link: "",
      coepris2_qr: "",
      target2: "",
      tiktok: "",
      like: false
    },
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],

    reservationFormDialog: false,
    reservation: {
      category: "doctors",
      id: "",
      date: "",
      hour: "",
    },
  }),
  computed: {
    containerCSS() {
      return {
        width: "100%",
        height: this.height,
      };
    },
    isFavorite() {
      return this.profile.like
    }
  },
  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
              "USA +1 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        case "MX":
          return (
              "MEX +52 (" +
              phn.substring(0, 3) +
              ") " +
              phn.substring(3, 6) +
              "-" +
              phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  methods: {
    registerVisit(){
      registerHistorial(this.$route.params.id, PROFILE_NAME, VISIT_PROFILE);
    },
    registerYoutubeVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, YOUTUBE_VIDEO);
    },
    registerUbicationVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, HOW_TO_GET_THERE);
    },
    registerLikeVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, LIKE);
    },
    registerInterestVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, IM_INTEREST);
    },
    registerCallUSVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, CALL_US);
    },
    registerItineraryVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, ADD_TO_ITINERARY);
    },
    registerItineraryButtonVisit() {
      registerHistorial(this.$route.params.id, PROFILE_NAME, ADD_TO_ITINERARY_BUTTON);
    },
    getSpanHeight() {
      const altura = this.$refs.descriptionSpan.offsetHeight;
      console.log('Altura del span:', altura);
    },
    goAddress() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.userLocation = `${position.coords.latitude},${position.coords.longitude}`;
              this.openGoogleMaps();
            },
            (error) => {
              console.error("Error al obtener la ubicación:", error);
              console.log("No se pudo obtener la ubicación. Asegúrate de que los servicios de ubicación estén habilitados.");
            }
        );
      } else {
        console.log("La Geolocalización no es compatible con este navegador.");
      }
      this.registerUbicationVisit();
    },
    openGoogleMaps() {
      if (this.userLocation) {
        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.userLocation}&destination=${this.profile.map_lat},${this.profile.map_lng}&travelmode=driving`;
        window.open(url, '_blank'); // Abre Google Maps en una nueva pestaña
      }
      this.registerUbicationVisit();
    },
    openReservationForm() {
      this.reservationFormDialog = true;
      this.registerItineraryButtonVisit();
    },

    closeReservationForm() {
      this.reservationFormDialog = false;
    },

    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0]; // Obtiene solo la parte de la fecha
      const fechaActual = new Date().toISOString().split("T")[0]; // Formatea la fecha actual en el mismo formato
      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },

    //cart

    addToCart(profile) {
      // Validar que la fecha y la hora estén seleccionadas
      if (!this.reservation.date || !this.reservation.hour) {
        this.snackText = "Select a date and time for the reservation.";
        this.snackBar = true;
      } else {
        this.reservationFormDialog = false;

        this.reservation.id = profile.id;

        const doctor = {
          category: "Doctor",
          name: profile.name,
          image: profile.pictures[0]?.url,
          profile: profile,
          reservation: this.reservation,
        };

        //   // Llama a la acción addToCart del store para agregar el doctor al carrito
        this.$store.dispatch("addToCart", doctor);
        this.registerItineraryVisit();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Reservation successfully added to itinerary.",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    //////////////////////////////////////////

    goBack() {
      this.$router.push("/doctors");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getDoctors() {
      doctorsService.getViewList().then((record) => {
        var valor = record.value;
        for (var i = 0; i < valor.length; i++) {
          if (valor[i].coepris_qr && valor[i].vencimiento_fecha) {
            if (this.isFechaVencida(valor[i].vencimiento_fecha)) {
              this.doctors.push(valor[i]);
            }
          }
        }
      });
    },
    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.profile.youtubeprofile.split(
          /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      // console.log("url", url);
      const YId =
          undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      // console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      this.youtubeVideo = topOfQueue;
    },
    getProfile(idDoctor) {
      let record = null;
      doctorsService.getRecord(idDoctor).then((fetch_data) => {
        record = fetch_data.value;
        this.profile.excellence = parseInt(record.excellence) === 1
        this.profile.repsabi = parseInt(record.repsabi) === 1
        this.profile.premium = parseInt(record.premium) === 1
        record.member !== null ? this.profile.member = parseInt(record.member) === 1 : this.profile.member = 0;
        record.basic !== null ? this.profile.basic = parseInt(record.basic) === 1 : this.profile.basic = 0;
        this.profile.tiktok = record.tiktok
        this.profile.id = record.id;
        this.checkFavorite()
        this.profile.name = record.name;
        this.profile.description = record.description;
        this.profile.description_english = record.description_english;
        this.profile.address = record.address;
        this.profile.map_lng = record.map_lng;
        this.profile.map_lat = record.map_lat;
        this.profile.email = record.email;
        this.profile.phone1 = record.phone1;
        this.profile.phone2 = record.phone2;
        this.profile.profesional_license_number =
            record.profesional_license_number;
        this.profile.specialty_license_number = record.specialty_license_number;
        this.profile.certification_validity = record.certification_validity;
        this.profile.certificate_number = record.certificate_number;
        this.profile.professional_license_verification =
            record.professional_license_verification;
        this.profile.website = record.web_page;
        this.profile.facebookprofile = record.facebook_profile;
        this.profile.instagramprofile = record.instagram_profile;
        this.profile.tweeterprofile = record.twitter_profile;
        this.profile.youtubeprofile = record.youtube_video;
        this.profile.servicehours = record.servicehours;
        this.profile.pictures = record.pictures;
        this.profile.specialties = record.specialties;
        this.profile.certifications = record.certifications;
        this.profile.coepris_qr = record.coepris_qr;
        this.profile.vencimiento_fecha = record.vencimiento_fecha;
        this.profile.coepris_qr_link = record.coepris_qr_link;

        if (this.profile.coepris_qr_link === "" || this.profile.coepris_qr_link === null) {
          this.profile.target2 = "#"
        }

        if (this.profile.basic
            || this.profile.id == 103
            || this.profile.id == 104
            || this.profile.id == 105
            || this.profile.id == 106
            || this.profile.id == 107
            || this.profile.id == 108
            || this.profile.id == 109
            || this.profile.id == 110
            || this.profile.id == 111
            || this.profile.id == 112
            || this.profile.id == 113
            || this.profile.id == 114
            || this.profile.id == 115
            || this.profile.id == 116
            || this.profile.id == 117
            || this.profile.id == 118
            || this.profile.id == 119
            || this.profile.id == 120
            || this.profile.id == 121
            || this.profile.id == 122
            || this.profile.id == 123
            || this.profile.id == 124
            || this.profile.id == 125
            || this.profile.id == 126
            || this.profile.id == 127
            || this.profile.id == 128
            || this.profile.id == 129
            || this.profile.id == 131
            || this.profile.id == 132
            || this.profile.id == 133
            || this.profile.id == 134
            || this.profile.id == 135
            || this.profile.id == 136
            || this.profile.id == 137
            || this.profile.id == 138
            || this.profile.id == 139
            || this.profile.id == 140
            || this.profile.id == 141
            || this.profile.id == 142
            || this.profile.id == 143
            || this.profile.id == 144
            || this.profile.id == 145
            || this.profile.id == 146
            || this.profile.id == 147
            || this.profile.id == 148
            || this.profile.id == 149
            || this.profile.id == 150
            || this.profile.id == 151
            || this.profile.id == 152
            || this.profile.id == 153
            || this.profile.id == 154
            || this.profile.id == 155
            || this.profile.id == 156
            || this.profile.id == 157
            || this.profile.id == 158
            || this.profile.id == 159
            || this.profile.id == 160
            || this.profile.id == 161
            || this.profile.id == 162
            || this.profile.id == 163
            || this.profile.id == 164
            || this.profile.id == 165
            || this.profile.id == 166
            || this.profile.id == 167
            || this.profile.id == 168
            || this.profile.id == 169
            || this.profile.id == 170
            || this.profile.id == 171
            || this.profile.id == 172
            || this.profile.id == 173
            || this.profile.id == 174
            || this.profile.id == 175
            || this.profile.id == 176
            || this.profile.id == 177
            || this.profile.id == 178
            || this.profile.id == 179
            || this.profile.id == 180
            || this.profile.id == 181
            || this.profile.id == 182
            || this.profile.id == 183
            || this.profile.id == 184
            || this.profile.id == 185
            || this.profile.id == 186
            || this.profile.id == 187
            || this.profile.id == 188
            || this.profile.id == 189
            || this.profile.id == 190
            || this.profile.id == 191
            || this.profile.id == 192
            || this.profile.id == 193
            || this.profile.id == 194
            || this.profile.id == 195
            || this.profile.id == 196
            || this.profile.id == 197
            || this.profile.id == 198
            || this.profile.id == 199
            || this.profile.id == 200
            || this.profile.id == 201
            || this.profile.id == 202
            || this.profile.id == 203
            || this.profile.id == 204
            || this.profile.id == 205
            || this.profile.id == 206
            || this.profile.id == 207
            || this.profile.id == 208
            || this.profile.id == 209
            || this.profile.id == 210
            || this.profile.id == 211
            || this.profile.id == 212
            || this.profile.id == 213
            || this.profile.id == 214
            || this.profile.id == 218
            || this.profile.id == 219
            || this.profile.id == 220
            || this.profile.id == 221
            || this.profile.id == 222
            || this.profile.id == 223
            || this.profile.id == 224
            || this.profile.id == 225
            || this.profile.id == 230
            || this.profile.id == 231) {
          this.profile.basic = 1;
        }

        this.getSpanHeight()

        changeInformationSEO(record.keywords, record.description_english);

        // Gestion de la ubicacion en el mapa.
        const lng = this.profile.map_lng;
        const lat = this.profile.map_lat;
        this.setLocationCoordinates({lng, lat});
        this.createMap();

        if (this.profile.youtubeprofile != null) {
          this.loadURL();
        }
        this.toTop();
      });
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.mapBoxApiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.location,
          zoom: 10,
        });
        // Controles basicos de navegacion: zoom y rotacion.
        this.map.addControl(new mapboxgl.NavigationControl());

        // Evento del mapa.
        this.map.on("load", () => {
          this.marker = new mapboxgl.Marker()
              .setLngLat(this.location)
              .addTo(this.map);
        });

        // Add Click Listener
        this.map.on("click", (event) => {
          console.log("evento: ", event);
          alert("Visitar el sitio oficial de MapBox");
          // window.location.href = 'https://www.mapbox.com/';
        });
      } catch (err) {
        console.error("map error", err);
      }
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat];
    },
    sendPreference(tag_type) {
      let tagText = tag_type === "mark" ? "Me interesa" : "Me Gusta";

      if (localStorage.getItem('likes')) {
        this.profile.likes = localStorage.getItem('likes');
        for (let i = 0; this.profile.likes.length - 1; i++) {
          if (this.profile.likes[i] == this.profile.name) {
            this.profile.like = true;
            break;
          } else {
            this.profile.likes.push(this.profile.name)
            localStorage.setItem(this.profile.likes);
            this.profile.like = true;
          }
        }
      } else {
        localStorage.likes = [];
        localStorage.setItem('likes', this.profile.name);
        this.profile.like = true;
      }
      console.log("Profile like: ", this.profile.like);
      preferencesService.addRecord(this.email, tag_type, 13).then((result) => {
        if (result.success) {
          this.snackText =
              tag_type === "mark"
                  ? `Se ha enviado los datos de esta vista etiquedada con ${tagText}! a su buzón de correo!`
                  : `La vista ha sido etiquetada con ${tagText}!`;

          // Enviar los datos por correo electronico.
          doctorsService.sendEmail(this.profile.id, this.email).then((data) => {
            if (data.success) {
              console.log("Correo enviado: ", data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Thank you for sharing your email, we will be in contact as soon as possible.",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              console.log("Error enviando correo: ", data);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Error sharing your email.",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
        } else {
          this.snackText = "Un error impidió etiquetar la vista!";
        }
        this.snackBar = true;
      });
    },
    openDialog() {
      this.email = "";
      this.dialog = true;
      this.registerInterestVisit();
    },
    closeDialog() {
      this.email = "";
      this.dialog = false;
    },
    acceptDialog() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.sendPreference("mark");
        this.dialog = false;
      }
    },
    addFavorite(favorites) {
      localStorage.setItem('doctors', JSON.stringify(favorites));
    },
    getFavorites() {
      const favoritesData = localStorage.getItem('doctors');
      if (favoritesData) {
        return JSON.parse(favoritesData);
      } else {
        return []; // Regresa un array vacío si no hay favoritos
      }
    },
    toggleFavorite() {
      const favorites = this.getFavorites()
      if (!favorites.includes(this.profile.id)) {
        favorites.push(this.profile.id)
      } else {
        const index = favorites.indexOf(this.profile.id);
        if (index > -1) {
          favorites.splice(index, 1);
        }
      }
      this.addFavorite(favorites);
      this.checkFavorite();
      this.registerLikeVisit();
    },
    checkFavorite() {
      const favorites = this.getFavorites()
      this.profile.like = favorites.includes(parseInt(this.profile.id));
    },
  },
  mounted() {
    this.getDoctors();
    this.getProfile(this.$route.params.id);
    this.registerVisit();
  },
};
</script>

<style scoped>
.text-container {
  max-height: 200px;
  width: 100%; /* Altura fija de 300px */
  overflow: scroll; /* Oculta el contenido desbordado */
  position: relative;
}

.text-container.expanded {
  height: auto; /* Permite que tome el tamaño total cuando esté expandido */
}

.v-btn {
  text-transform: none !important;
}

.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 15px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}

.bottom-gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(114, 217, 235, 0.185) 0%,
      rgba(101, 99, 248, 0.911) 100%
  );
}

.my-slider > .v-slide-group__next.theme--light.v-icon {
  color: rgb(234, 10, 10) !important;
  font-size: 24pt !important;
}

.img-qr {
  height: 110px;
  width: 110px
}

.img-qr-rigth {
  width: 100%;
  background-color: #6a1b31;
  height: 110px;
  object-fit: contain
}

.img-container {
  height: 500px
}

.superior-derecha {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 98px;
  height: 90px;
}

.superior-derecha-segunda {
  position: absolute;
  top: 95px;
  right: 5px;
  width: 100px;
  height: 100px;
}

.superior-derecha-tercera {
  position: absolute;
  top: 195px;
  right: 15px;
  width: 80px;
  height: 80px;
}

.superior-derecha-card {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 50px;
  height: 50px;
}

.certification-icon-card {
  width: 130px;
  height: 100px;
  margin: 5px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.documents-icon-card {
  width: 130px;
  margin: 5px;
  vertical-align: top;
  cursor: pointer;
}

.superior-derecha-segunda-card {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 60px;
  height: 60px;
}

.superior-derecha-tercera-card {
  position: absolute;
  top: 100px;
  right: 9px;
  width: 43px;
  height: 43px;
}

.img-doctor {
  object-fit: contain;
  object-position: top;
}

@media (max-width: 767px) {
  .img-container {
    height: 354px;
    overflow: hidden;
  }

  .img-doctor {
    object-fit: cover;
    height: 100%;
    object-position: top;
  }

  .superior-derecha {
    position: absolute;
    top: 10px;
    right: 2px;
    width: 90px;
    height: 80px;
  }

  .superior-derecha-segunda {
    position: absolute;
    top: 90px;
    right: 0px;
    width: 95px;
    height: 95px;
  }

  .superior-derecha-tercera {
    position: absolute;
    top: 190px;
    right: 12px;
    width: 70px;
    height: 70px;
  }
}

.modal-email {
  padding: 30px;
}

.certification-icon-card-zoom {
  height: 500px;
  margin: 5px;
  object-fit: cover;
  object-position: center;
}

.zoom-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .img-qr {
    height: 60px;
    width: 60px
  }

  .img-qr-rigth {
    width: 100%;
    background-color: #6a1b31;
    height: 60px;
    object-fit: contain
  }
}
</style>
